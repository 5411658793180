import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Category header',
  props: {
    category: {} as any,
    displayAllPosts: { type: 'boolean', defaultValue: true },
  },
  methods: {},
  events: {},
});
